import React from "react";
import { Form, Formik } from "formik";
import ModalWrapper from "../../app/common/modals/ModalWrapper";
import * as Yup from "yup";
import CustomTextInput from "../../app/common/form/CustomTextInput";
import { Button, Divider, Label } from "semantic-ui-react";
import { useDispatch } from "react-redux";
import { closeModal } from "../../app/common/modals/modalReducer";
import { signInWithEmail } from "../../app/firestore/firebaseService";
import SocialLogin from "./SocialLogin";

export default function LoginForm() {
	const dispatch = useDispatch();
	return (
		<ModalWrapper size='mini' header='Sign in to Re-vents'>
			<Formik
				initialValues={{ email: "", password: "" }}
				validationSchema={Yup.object({
					email: Yup.string().required().email(),
					password: Yup.string().required()
				})}
				onSubmit={async (values, { setSubmitting, setErrors }) => {
					try {
						await signInWithEmail(values);
						dispatch(closeModal());
					} catch (error) {
						setErrors({ auth: "Problem with username or password" });
					} finally {
						setSubmitting(false);
					}
				}}
			>
				{({ isSubmitting, isValid, dirty, errors }) => (
					<Form className='ui form'>
						<CustomTextInput name='email' placeholder='Email Address' />
						<CustomTextInput name='password' placeholder='Password' type='password' />
						{errors.auth && (
							<Label basic color='red' style={{ marginBottom: 10 }} content={errors.auth} />
						)}
						<Button
							loading={isSubmitting}
							disabled={!isValid || !dirty || isSubmitting}
							type='submit'
							fluid
							size='large'
							color='teal'
							content='Login'
						/>
						<Divider horizontal>Or</Divider>
						<SocialLogin />
					</Form>
				)}
			</Formik>
		</ModalWrapper>
	);
}
